import { Token } from "shared/types/user";
import { Fx } from "../types/api";
import { createApi } from "./_factories/createApi";

const structure = "smssignature";
const api = createApi({ structure });

export type SignInCheckSMSProps = {
  id: string;
  smsCodeInvestor: string;
};

export type SendSMSSignInProps = {
  type: "signIn";
  idInvestor: string;
  email?: never;
  firstName?: never;
};

export type SendSMSSignUpProps = {
  type: "signUp";
  firstName: string;
  email: string;
  idInvestor?: string;
};

export type SendSMSProps = SendSMSSignInProps | SendSMSSignUpProps;

export type StatusSMS = {
  id: string;
  idInvestor: string;
  status: string;
  email?: string;
  firstName?: string;
  type?: string;
  token?: Token;
};

const send: Fx<SendSMSProps, StatusSMS, Error> = async (data) =>
  await api().setItem<StatusSMS>("sendSMS", data);

const check: Fx<SignInCheckSMSProps, StatusSMS, Error> = async (data) =>
  await api().setItem<StatusSMS>("checkSMS", data);

const post: Fx<SignInCheckSMSProps, StatusSMS, Error> = async (data) =>
  await api().setItem<StatusSMS>("checkSMS", data);

export type GetParams = {
  id?: string;
};

const get: Fx<GetParams, StatusSMS, Error> = async (params) => {
  try {
    const response = await api().getItem<StatusSMS>("checkSMS", params);
    if (!response) throw { response: { data: { msg: "СМС не найдена" } } };
    else return response;
  } catch (err: any) {
    throw err;
  }
};

const checkNew = {
  get,
  post,
};

export type ResendSMSProps = {
  id?: string;
};

const resend: Fx<ResendSMSProps, StatusSMS, Error> = async (params) =>
  await api().setItem<StatusSMS>("resendSMS", params);

export type CheckSMSIsExistAndActiveData = {
  smsSignatureId: string;
};

const checkIsExistAndActive: Fx<CheckSMSIsExistAndActiveData, StatusSMS, Error> = async (data) =>
  await api("smssignatureexist").setItem<StatusSMS>("check", data);

const smsFx = { check, resend, send, checkNew, checkIsExistAndActive };

export default smsFx;
