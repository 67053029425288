export interface YandexMetrikaInstance {
  (...args: unknown[]): void;
  a?: unknown[];
  l?: number;
}

declare global {
  interface Window {
    ym?: YandexMetrikaInstance;
  }
}

const YM_CODE = 97866819;

function ym(eventType: string, eventName: string, ...args: unknown[]): void {
  if (window.ym) {
    window.ym(97866819, eventType, eventName, ...args);
  }
}

export { ym, YM_CODE };
