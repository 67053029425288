import React from "react";

const InnerHTML = ({ html }: { html: string }) => {
  React.useEffect(() => {
    const scripts = html.match(/<script.*?>([\s\S]*?)<\/script>/g);

    scripts?.forEach((script) => {
      const newScript = document.createElement("script");
      newScript.textContent = script.replace(/<script.*?>|<\/script>/g, "").trim();
      document.body.appendChild(newScript); // Добавляем в DOM для выполнения
    });
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default InnerHTML;
