import InnerHTML from "components/_common/InnerHTML";
import { ReactNode } from "react";
import { YM_CODE } from "../";

const script = () => `<script type="text/javascript" >
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(${YM_CODE}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
    });
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/${YM_CODE}" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
`;

const PROD_HOST_NAME = "lk.xn--80adjkzugi.xn--p1ai";

const Ymetrika = ({ children }: { children: ReactNode | ReactNode[] }) => {
  let location = window.location;

  if (location.host !== PROD_HOST_NAME) {
    return <>{children}</>;
  }

  return (
    <>
      <InnerHTML html={script()} />
      {children}
    </>
  );
};

export { Ymetrika };
